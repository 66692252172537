<template>
  <div>
    <h2 class="mb-4">Страны</h2>

    <countries-filters :table-params="tableParams" @change-field="changeField" />

    <data-table
      ref="dataTable"
      url="countries"
      item-key="alpha2Code"
      clickable-rows
      :headers="headers"
      :table-params="tableParams"
      @click:row="editCountry"
    />
  </div>
</template>

<script>
import { editorForm } from "~/mixins/multi-editor/editor-form";

import CountriesFilters from "~/components/countries/filters";

export default {
  mixins: [editorForm],
  data() {
    return {
      headers: [
        { text: "Страна EN", value: "nameEn", sortable: true },
        { text: "Страна RU", value: "name", sortable: true },
        { text: "Вес", value: "weight", sortable: true }
      ],
      tableParams: {
        title: "",
        isVisible: 1
      }
    };
  },
  methods: {
    editCountry(country) {
      this.$router.push({ name: "countries-code", params: { code: country.alpha2Code } });
    },
    changeField(payload) {
      this.commitChange(this.tableParams, payload);
    }
  },
  components: {
    CountriesFilters
  }
};
</script>
