<template>
  <v-sheet elevation="3" class="pa-3">
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-text-field label="Поиск" :value="tableParams.name" clearable @input="setField('title', $event)" />
      </v-col>

      <v-col cols="12" sm="6" md="6">
        <v-checkbox v-model="isVisibleProxy" label="В списке" />
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { editorFields } from "~/mixins/multi-editor/editor-fields";

export default {
  mixins: [editorFields],
  props: {
    tableParams: {
      type: Object,
      required: true
    }
  },
  computed: {
    isVisibleProxy: {
      get() {
        return Boolean(this.tableParams.isVisible);
      },
      set(value) {
        this.setField("isVisible", value ? 1 : 0);
      }
    }
  }
};
</script>
